var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = "/api/sentry";
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"f81b5d44532baa112c967b9dbb8a116bf0fe3f08"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

Sentry.init({
  dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  tracesSampleRate: 0.2,
  tracePropagationTargets: [
    /^\//, // All URLs on current origin.
    /^https:\/\/api\.inngest\.com\//, // The production API origin.
    /^https:\/\/api\.inngest\.net\//, // The staging API origin.
    'localhost', // The local API origin.
  ],
  replaysSessionSampleRate: 0.0,
  replaysOnErrorSampleRate: 1.0,
});

Sentry.getCurrentHub?.()
  ?.getClient?.()
  ?.addIntegration?.(
    new Sentry.Replay({
      maskAllText: false,
      blockAllMedia: false,
    })
  );
